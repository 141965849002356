.Landing {
    height: 100vh;
    width: 100vw;
    display: flex;
}
.Logo {
    text-decoration: none;
}

@media (max-width: 599px) {
    .Landing {
        flex-direction: column-reverse;
        position: relative;
        overflow: hidden;
    }
    .ImageContainer {
        width: 100vw;
        background-color: #f6f5f7;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .ImageDesktop {
        display: none;
    }
    .ImageMobile {
        width: 105%;
    }
    .Logo {
        position: absolute;
        top: 7px;
        left: 20px;
        z-index: 1;
        height: 50px;
        font-family: "Recoleta";
        font-size: 32px;
        color: rgb(73, 20, 208);
        color: red;
        text-align: justify;
    }
    .Content {
        width: 100%;
        height: 48%;
        background-color: white;
        border-top-left-radius: 40px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

@media (min-width: 600px) {
    .Landing {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .Content {
        width: 38%;
        height: 100%;
        background-color: white;
        padding: 30px 0 0 0;
    }
    .Logo {
        height: 60px;
        /* background-color: pink; */
        font-family: "Recoleta";
        font-size: 35px;
        color: rgb(73, 20, 208);
        text-align: justify;
        margin-left: 7%;
    }
    .ImageContainer {
        width: 62%;
        height: 100%;
        background-color: #f6f5f7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ImageDesktop {
        display: block;
        width: 85%;
        /* box-shadow: 0 15px 35px rgba(51, 0, 255, 0.3); */
    }
    .ImageMobile {
        display: none;
    }
}

