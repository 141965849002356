.BtnGeneral {
    border: 0;
    outline: none;
    text-decoration: none;
    color: white;
    text-align: center;
    cursor: pointer;
}
.Btn1 {
    padding: 8px 30px;
    width: 140px;
    border-radius: 8px;
    margin-right: 20px;
    box-shadow: 0 2px 4px rgba(55, 5, 140, 0.53);
}
.Btn2 {
    padding: 10px 20px;
    min-width: 130px;
    border-radius: 5px;
    font-size: 13px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(55, 5, 140, 0.53);
    background-color: #79589f;
    background-image: linear-gradient(
        to left top,
        rgba(171, 142, 205, 0) 0,
        #ab8ecd 100%
    );
}
.Btn3 {
    padding: 8px 30px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid rgb(55, 5, 140);
    box-shadow: 0 2px 4px rgba(55, 5, 140, 0.53);
    color: rgb(55, 5, 140);
    background-color: none;
    transition: background 0.2s ease-in-out;
}
.Btn3:hover {
    color: white;
    background: rgb(55, 5, 140);
    /* transition: background-color 0.6 ease-in-out; */
    transition: background 0.2s ease-in-out;
}
