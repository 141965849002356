.Profile {
    /* background-color: pink; */
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 70vh;
}
.TextBlue {
    text-align: center;
    line-height: 24px;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10%;
    font-weight: 700;
    color: #3a2ae4;
    max-width: 300px;
}
.Profile .Form {
    margin-top: 3%;
}
.h2 {
    text-align: center;
    font-size: 60px;
    color: black;
    margin-bottom: 20px;
}
.User {
    text-transform: capitalize;
}
.Profile .ButtonsContainer {
    margin-top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.BtnBottom {
    justify-self: end;
}
.DeleteExplanation {
    font-size: 13px;
    max-width: 350px;
    /* margin-top: 20px; */
}
