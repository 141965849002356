.Active > .FontIcons {
    fill: #1500ff;
}
.Tip {
    font-family: "DM Sans", sans-serif;
}
.Meta > * {
    color: rgb(48, 52, 70);
}
.Meta h6 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
}
.Subtitle {
    font-size: 15px;
    margin-bottom: 15px;
    color: rgb(143, 156, 178);
    /* font-weight: 500; */
}
.TechnologiesIconsBox {
    box-shadow: rgba(199, 205, 216, 0.3) 0px 4px 12px 0px;
    background-color: rgba(237, 224, 250, 0.85);
    background-color: white;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
    border: 0.3px solid rgb(237, 224, 250);
}
.TechnologiesIconsBox > div {
    margin-top: 3px;
    margin-bottom: -5px;
}
.Meta span > h5 {
    font-weight: 400;
    font-size: 14px;
}

.Meta span > div > * {
    margin: 0 6px;
    margin-top: 4px;
    font-size: 19px;
}
.Technologies {
    border-radius: 4px;
    border: 0.3px solid rgb(237, 224, 250);
    box-shadow: rgba(199, 205, 216, 0.3) 0px 4px 12px 0px;
    background-color: #fff;
    font-size: 12px;
    cursor: context-menu;
}
.Technology:hover {
    background-color: rgba(222, 212, 248, 0.2);
    transition: background-color 0.3s ease-out;
}
.Technology:hover > .Name {
    color: #7300ff;
}
.Titles {
    color: rgb(48, 52, 70);
    background-color: rgb(48, 52, 70);
    display: grid;
    grid-template-columns: 20% 35% 45%;
    list-style: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 5px 20px;
}
.Titles > li {
    color: white;
    padding: 2px 5px;
    font-size: 14px;
}
.Table {
    padding: 5px 10px 15px 10px;
    max-height: 270px;
    overflow-y: scroll;
}
.Technology:last-child {
    border-bottom: none !important;
}
.Name {
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
}
.Feat {
    background-color: rgba(176, 169, 231, 0.218);
    border-radius: 3px;
    padding: 2px 6px;
}
.Table::-webkit-scrollbar {
    width: 12px;
    background-color: #aaa;
}
.Table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    background-color: #fff;
}
.Table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #aaa;
}
.FontIcons:hover {
    fill: #7300ff;
    cursor: pointer;
    transition: color 0.5s linear;
}
.Subscribe {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    font-size: 12px;
    font-weight: 400;
    background-color: #7300ff1f;
    border-left: 3px solid #7300ff;
    border-radius: 3px;
    height: 50px;
}

.Subscribe input {
    border: none;
    border-bottom: 0.2px solid rgba(167, 157, 176, 0.755);
    outline: none;
    padding: 1px 15px;
    color: black;
    font-family: "Recoleta";
    font-size: 16px;
    font-weight: 300;
    height: 34px;
    width: 250px;
    background-color: rgba(255, 255, 255, 0.04);
    text-align: right;
}
.Subscribe input:hover {
    border-bottom: 2px solid #7300ff;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.4s linear;
}
.Subscribe input:focus {
    border: 0.3px solid#6d13ff;
    outline: none;
    border-radius: 3px;
}

.btnSubscribe {
    margin: 0;
    border: none;
    outline: none;
    background-color: #6d43b1;
    padding: 0 10px;
    color: snow;
    border-radius: 4px;
    cursor: pointer;
    height: 34px;
    box-shadow: rgba(199, 205, 216, 0.3) 0px 4px 12px 0px;
    border: 0.3px solid#6d13ff;
}
.btnSubscribe:hover {
    background-color: #9d69f1;
    transition: background-color 0.2s linear;
}
