.Github {
    position: absolute;
    bottom: 12px;
    right: 3%;
}

.Github p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 200;
    padding-bottom: 6px;
    margin-right: 10px;
    color: #000000;
}
a.GithubLink {
    text-decoration: none;
    display: flex;
    align-items: center;
}
.Github p:hover {
    color: rgb(40, 0, 202);
    transition: color 0.4s ease-in-out;
}
.GithubIcon {
    font-size: 24px;
    /* color: red; */
}
