* {
    margin: 0;
    padding: 0;
    font-family: "DM Sans", sans-serif;
    color: black;
    box-sizing: border-box;
}

.App {
    text-align: center;
    height: 100vh;
    width: 100vw;
}

@font-face {
    font-family: "Recoleta";
    src: local("Recoleta"),
        url(./assets/fonts/Recoleta-SemiBold.woff) format("woff");
}

/*  css variables  */
:root {
    --orange: rgb(238, 94, 37);
}
