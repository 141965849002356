.Canvas {
    transition: 0.4s;
    margin-bottom: 30px;
    background: none;
    border-bottom: 3px solid rgb(238, 94, 37);
}
.Canvas:hover {
    background-color: rgba(238, 229, 250, 0.728);
    transition: 0.3s;
}
.Center {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.DeleteExplanation {
    font-size: 13px;
    max-width: 350px;
    margin-top: 20px;
}

a.LinkToProfile {
    border-bottom: 4px solid #ab8ecd;
    text-decoration: none;
}
a.LinkToProfile:hover {
    border-bottom: 4px solid #7342ac;
    transition: border-bottom 0.3s;
}
.BtnBottom {
    margin-top: 35px;
    margin-bottom: -25px;
}
